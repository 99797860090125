import React from "react"
import { ExhibitionContainer } from "./styles"
import Layout from "../share/layout";

const Tercero = () => {
    return(
      <Layout>
        <ExhibitionContainer>
          <div className="title-exh">
          <h2>ARQUITECTURAS DE LA IMAGINACIÓN UPC<br />[TERCER AÑO]</h2>
              <a href='/galeria/arquitectura-upc-2022-2'><h4>VOLVER</h4></a>
          </div>
          <div className="container-iframe">
          <iframe
            allowfullscreen="true"
            width="100%"
            height="100%"
            frameBorder="0"
            scrolling="no"
            src='https://art.kunstmatrix.com/apps/artspaces/?external=true&uid=58146&exhibition=11287387'
            title='Arquitecturas de la Imaginación [Tercer Año]'
          />
          </div>
        </ExhibitionContainer>
      </Layout>
    )
}

export default Tercero